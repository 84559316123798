// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-en-tsx": () => import("./../../../src/pages/about.en.tsx" /* webpackChunkName: "component---src-pages-about-en-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-en-tsx": () => import("./../../../src/pages/blog.en.tsx" /* webpackChunkName: "component---src-pages-blog-en-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-consulting-en-tsx": () => import("./../../../src/pages/consulting.en.tsx" /* webpackChunkName: "component---src-pages-consulting-en-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-employers-en-tsx": () => import("./../../../src/pages/employers.en.tsx" /* webpackChunkName: "component---src-pages-employers-en-tsx" */),
  "component---src-pages-employers-tsx": () => import("./../../../src/pages/employers.tsx" /* webpackChunkName: "component---src-pages-employers-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-en-tsx": () => import("./../../../src/pages/jobs.en.tsx" /* webpackChunkName: "component---src-pages-jobs-en-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-tech-agents-sv-tsx": () => import("./../../../src/pages/tech-agents.sv.tsx" /* webpackChunkName: "component---src-pages-tech-agents-sv-tsx" */),
  "component---src-pages-tech-agents-tsx": () => import("./../../../src/pages/tech-agents.tsx" /* webpackChunkName: "component---src-pages-tech-agents-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-the-app-sv-tsx": () => import("./../../../src/pages/the-app.sv.tsx" /* webpackChunkName: "component---src-pages-the-app-sv-tsx" */),
  "component---src-pages-the-app-tsx": () => import("./../../../src/pages/the-app.tsx" /* webpackChunkName: "component---src-pages-the-app-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

